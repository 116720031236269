import ZaemRuLogo from "./images/zaemru-logo.svg";
import DoZarplateLogo from "./images/dozarplate-logo.png";
import TurboZaimLogo from "./images/turbozaim-logo.png";
import SrochnoDengiLogo from "./images/srochnodengi-logo.png";
import KvikuLogo from "./images/kviku-logo.png";
import MaxCreditLogo from "./images/maxcredit-logo.png";
import ZaymigoLogo from "./images/zaymigo-logo.png";
import SmartCashLogo from "./images/smartcash-logo.png";

export interface IOffer {
  offer_link: string;
  logo: string;
  special: string;
  min: string;
  max: string;
  min_date: string;
  max_date: string;
  percent_min_day: string;
  percent_max_day: string;
  percent_min: string;
  percent_max: string;
  company_data: {
    name: string;
    juridical_form: string;
    ogrn: string;
    inn: string;
    phone: string;
    address: string;
    license: string;
  };
  logoWidth?: string;
  logoHeight?: string;
  logoPaddingTop?: string;
}

export const offers: IOffer[] = [
  {
    offer_link:
      "https://pxl.leads.su/click/d292838faa81e387106ebd8906551f6c?erid=LjN8Jzcg7",
    logo: ZaemRuLogo,
    special: "Первый заём бесплатно",
    min: "1 000",
    max: "100 000",
    min_date: "1",
    max_date: "365",
    percent_min_day: "0",
    percent_max_day: "0,6",
    percent_min: "0",
    percent_max: "292",
    company_data: {
      name: "МФК ЭКСПРЕССДЕНЬГИ",
      juridical_form: "ООО",
      ogrn: "1101215004593",
      inn: "1215150103",
      phone: "8-800-250-8980",
      address: "424000, г. Йошкар-Ола, бульвар Чавайна, д.12а",
      license: "2120512002049",
    },
  },
  {
    offer_link:
      "https://pxl.leads.su/click/a6fb66a951f22359c26f8fcfefb1b49a?erid=LjN8KJEEN",
    logo: ZaymigoLogo,
    special: "Круглосуточно",
    min: "1 000",
    max: "15 000",
    min_date: "1",
    max_date: "30",
    percent_min_day: "0",
    percent_max_day: "0,8",
    percent_min: "0",
    percent_max: "292",
    company_data: {
      name: "Займиго МФК",
      juridical_form: "ООО",
      ogrn: "1135260005363",
      inn: "5260355389",
      phone: "8 (800) 200-00-10",
      address: "119017, г. Москва, Старомонетный переулок, дом 3",
      license: "651303322004222",
    },
  },
  {
    offer_link:
      "https://pxl.leads.su/click/46bcc61972d78fe4e95266aec340889e?erid=LjN8KP7zQ",
    logo: KvikuLogo,
    special: "Быстрое одобрение",
    min: "1 000",
    max: "100 000",
    min_date: "60",
    max_date: "360",
    percent_min_day: "0",
    percent_max_day: "0,8",
    percent_min: "0",
    percent_max: "292",
    company_data: {
      name: "МФК ЭйрЛоанс",
      juridical_form: "ООО",
      ogrn: "1137746543296",
      inn: "7716748537",
      phone: "+7 (499) 649-66-75",
      address: "107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11",
      license: "651303045003744",
    },
  },
  {
    offer_link:
      "https://pxl.leads.su/click/d9a1ada33556ed1932b906f42fef1ba5?erid=LjN8JzLd3",
    logo: DoZarplateLogo,
    special: "До 99% одобрение",
    min: "1 000",
    max: "100 000",
    min_date: "15",
    max_date: "365",
    percent_min_day: "0",
    percent_max_day: "1",
    percent_min: "0",
    percent_max: "365",
    company_data: {
      name: "МКК ДЗП-Единый",
      juridical_form: "ООО",
      ogrn: "1237800038442",
      inn: "7814821288",
      phone: "8 (812) 426-77-72",
      address:
        "197374, г. Санкт-Петербург, Мебельная улица, дом 12, корпус 1, лит. А, помещение 53-Н, ком. 428",
      license: "2303140009956",
    },
    logoWidth: "150px",
    logoHeight: "auto",
    logoPaddingTop: "14px",
  },
  {
    offer_link:
      "https://pxl.leads.su/click/da1d33ff1414b27406d49b38ef980dae?erid=LjN8KCphN",
    logo: TurboZaimLogo,
    special: "Высокий % одобрения",
    min: "3 000",
    max: "15 000",
    min_date: "7",
    max_date: "168",
    percent_min_day: "0",
    percent_max_day: "0,8",
    percent_min: "0",
    percent_max: "292",
    company_data: {
      name: "МКК Турбозайм",
      juridical_form: "ООО",
      ogrn: "1137746702367",
      inn: "7702820127",
      phone: "8 499 951 91 80",
      address:
        "121087, город Москва, улица Барклая, дом 6, строение 5, помещение 23П/4",
      license: "651303045003951",
    },
  },
  {
    offer_link:
      "https://pxl.leads.su/click/44a2d99352ab2c3d9553208cc40717f0?erid=LjN8KCigY",
    logo: SrochnoDengiLogo,
    special: "Решение за 1 минуту",
    min: "2 000",
    max: "30 000",
    min_date: "1",
    max_date: "30",
    percent_min_day: "0",
    percent_max_day: "0,8",
    percent_min: "0",
    percent_max: "292",
    company_data: {
      name: "МКК Срочноденьги",
      juridical_form: "ООО",
      ogrn: "1105260001857",
      inn: "5260271530",
      phone: "8 (800) 1001-363",
      address:
        "603022, Нижегородская обл., г. Нижний Новгород, ул. Тимирязева, д 15, корп. 2, помещ. П406",
      license: "2110552000304",
    },
  },
  // {
  //   offer_link:
  //     "https://pxl.leads.su/click/a6817e1b6d908b0941c53595ea22afa1?erid=LjN8KLsMQ",
  //   logo: MaxCreditLogo,
  //   special: "На карту за 15 минут",
  //   min: "3 000",
  //   max: "30 000",
  //   min_date: "5",
  //   max_date: "30",
  //   percent_min_day: "0,8",
  //   percent_max_day: "0,8",
  //   percent_min: "0",
  //   percent_max: "292",
  //   company_data: {
  //     name: "МКК МАКС.КРЕДИТ",
  //     juridical_form: "ООО",
  //     ogrn: "1147847358383",
  //     inn: "7801640661",
  //     phone: "8-800-555-95-32",
  //     address:
  //       "191187, г. Санкт-Петербург, вн.тер.г. муниципальный округ Литейный округ, ул. Шпалерная, д. 8, литера А, помещ. 16-Н, ком. 4",
  //     license: "651503140006267",
  //   },
  // },
  // {
  //   offer_link:
  //     "https://pxl.leads.su/click/d12ea32bdb021b3ae2805fdb7ece5ab0?erid=LjN8KVaLS",
  //   logo: SmartCashLogo,
  //   special: "На карту за 15 минут",
  //   min: "3 000",
  //   max: "30 000",
  //   min_date: "5",
  //   max_date: "30",
  //   percent_min_day: "0,8",
  //   percent_max_day: "0,8",
  //   percent_min: "0",
  //   percent_max: "292",
  //   company_data: {
  //     name: "МКК УМНЫЕ НАЛИЧНЫЕ",
  //     juridical_form: "ООО",
  //     ogrn: "1217700073964",
  //     inn: "9725044900",
  //     phone: "8-800-333-02-73",
  //     address: "191036, Санкт-Петербург, а/я 12",
  //     license: "2103045009694",
  //   },
  // },
];

const offersCompanies: IOffer["company_data"][] = [];

offers.forEach((offer) => {
  offersCompanies.push(offer.company_data);
});

export const siteInfo = `Общие требования к заемщику: возраст от 18 лет, гражданство РФ, постоянная регистрация на территории РФ.

Информация о полной стоимости займа и пример расчета: ПСК (полная стоимость кредита) в процентах составляет до 292% годовых. Пример расчета - займ 10 000 рублей, срок пользования 12 недель под 0.83% в день, проценты за весь период составят 4 164,52 рублей. Итого к выплате 14 164,52 рублей.

Последствия невыплаты заемных средств: в случае невозвращения в условленный срок суммы кредита или суммы процентов за пользование заёмными средствами, 
кредитор вынуждено начислит штраф за просрочку платежа. Большинство кредиторов идут на уступки и дают 3 дополнительных рабочих дня для оплаты. Они предусмотрены на случай, 
к примеру, если банковский перевод занял больше времени, чем обычно. Однако, в случае неполучения от Вас какой-либо реакции в течение продолжительного времени, 
будет начислен штраф за просрочку срока погашения размером в среднем 0,10% от первоначальной суммы для займов. При несоблюдении Вами условий по погашению кредитов и займов, 
данные о Вас могут быть переданы в реестр должников, а задолженность — коллекторскому агентству для взыскания долга. Рекомендуем Вам вносить платеж в день получения напоминаний. 
Погашая задолженность в срок, Вы формируете хорошую кредитную историю, что повышает Ваши шансы в дальнейшем получить кредит на более выгодных условиях. 

Более подробные требования, условия получения, а также размер ставки уточняйте на сайтах размещенных организаций (кредиторов). Окончательные условия получения будут предложены в индивидуальном порядке.

Информация о кредиторах (организациях) на сайте: ${offersCompanies.map(
  (company) => `
${company.juridical_form} "${company.name}", ОГРН: ${company.ogrn}, ИНН: ${company.inn}, Телефон: ${company.phone}, Адрес: ${company.address}, Реестр ЦБ: ${company.license}`
)}`;

export const siteDetails = `займцентр.рф — сервис подбора займов. Сайт не выдает займов и кредитов. Вся представленная на сайте информация, касающаяся финансовых услуг или продуктов, носит информационный характер и ни при каких условиях не является публичной офертой, 
  определяемой положениями статьи 437 Гражданского кодекса РФ. Заполнение тех или иных форм, а также нажатия на кнопку "Получить деньги" не накладывает на владельцев сайта никаких обязательств, и не гарантирует получение денежных средств. Все материалы, 
  размещенные на сайте являются собственностью владельцев сайта, либо собственностью организаций, с которыми у владельцев сайта есть соглашение о размещении материалов. Для аналитических целей на сайте работает система статистики, которая собирает информацию 
  о посещенных страницах сайта, заполненных формах и т.д. Оставляя тем или иным способом свои персональные данные (информацию о себе), Вы предоставляете право обрабатывать вашу персональную информацию. Кадыров Амир Ренатович, +78432545147, г. Казань пр-кт победы ул. Р-Зорге, ИНН 166016199009`;
