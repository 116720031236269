import { FC } from "react";
import { IOffer } from "../../data";
import s from "./Offer.module.scss";

const Offer: FC<{ offer: IOffer }> = ({ offer }) => {
  return (
    <li className={s.offer}>
      <a href={offer.offer_link} className={s.offer_link}>
        <img
          src={offer.logo}
          alt={offer.company_data.name}
          className={s.offer_logo}
          style={
            offer.logoWidth && offer.logoHeight && offer.logoPaddingTop
              ? {
                  width: offer.logoWidth,
                  height: offer.logoHeight,
                  paddingTop: offer.logoPaddingTop,
                }
              : {}
          }
        />

        <p className={s.offer_special}>{offer.special}</p>

        <ul className={s.offer_conditions}>
          <li className={s.offer_condition}>
            <span className={s.offer_condition__title}>Сумма</span>
            <div className={s.offer_condition__content}>
              {offer.min}₽ — {offer.max}₽
            </div>
          </li>

          <li className={s.offer_condition}>
            <span className={s.offer_condition__title}>Срок</span>
            <div className={s.offer_condition__content}>
              от {offer.min_date} до {offer.max_date} дней
            </div>
          </li>

          <li className={s.offer_condition}>
            <span className={s.offer_condition__title}>Ставка</span>
            <div className={s.offer_condition__content}>
              {offer.percent_min_day} — {offer.percent_max_day}%
            </div>
          </li>
        </ul>

        <a href={offer.offer_link} className={s.offer_button}>
          <button className={s.offer_button__content}>Получить деньги</button>
        </a>

        <p className={s.offer_info}>
          Реклама "{offer.company_data.juridical_form} {offer.company_data.name}
          ". ПСК {offer.percent_min}—{offer.percent_max}%
        </p>
      </a>
    </li>
  );
};

export default Offer;
