import { offers, siteDetails, siteInfo } from "./data";

import Hand from "./images/hand.png";
import Offer from "./components/Offer/Offer";

import s from "./styles.module.scss";

function App() {
  return (
    <main>
      <header className={s.header}>
        <div className={s.logo}>
          займ<span className={s.logo_modifed}>центр</span>.рф
        </div>
      </header>

      <section className={s.banner}>
        <div className={s.banner_text}>
          <h1 className={s.banner_title}>
            Получите займ онлайн на карту на выгодных условиях
          </h1>
          <h2 className={s.banner_description}>
            За 15 минут, ставка от 0%, проверенные МФО, высокий % одобрения
          </h2>
        </div>
        <img src={Hand} alt="Рука" className={s.banner_img} />
      </section>

      <section className={s.offers}>
        <ul className={s.offers_list}>
          {offers.map((offer) => (
            <Offer offer={offer} />
          ))}
        </ul>
      </section>

      <section className={s.text}>{siteInfo}</section>

      <hr />

      <section className={s.text2}>{siteDetails}</section>
    </main>
  );
}

export default App;
